<template>
  <div class="not-found-component">404</div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  components: {}
})
export default class NotFound extends Vue {}
</script>

<style lang="scss">
.not-found-component {
  font-size: 10rem;
  font-weight: bold;
}
</style>
